import { Box, Button, FormLabel, PseudoBox, Stack, useToast, Grid, Textarea, Spinner } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCompDetailById, getCompanyTypes, getLinkTypeById } from '../../../api/web-information'
import { VALUE_TYPE_KEYS, sketchPickerChangeColor } from '../../../helpers/commons'
import { getToken, getUserApplicationId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { useFormControls } from '../../hooks'
import FormInput from '../form-input'
import { SketchPicker } from 'react-color'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import ImageInput from '../../common/image-input'
import { toastCustomizedError } from '../../common/toast-default-config'
import { ColorPicker } from 'antd'
import CustomCheckbox from '../../common/CustomCheckbox'
import './styles.css'

const WebDataForm = ({ onSubmit, formData = {}, ...restProps }) => {
  const { t } = useTranslation(['forms'])
  const toast = useToast()
  const token = useSelector(getToken)
  const appId = useSelector(getUserApplicationId)
  const maxCharacterCount = 500
  const { COLOR, IMAGE, VALUE, LINK } = VALUE_TYPE_KEYS

  const inputTextEntries = [VALUE, LINK]

  const [characterCount, setCharacterCount] = useState(formData.valor?.length || 0)
  const [color, setColor] = useState(formData.color || '#8dd3bb')
  const [textColor, setTextColor] = useState(formData.textColor || '#FFFFFF')
  const [valueValue, setValueValue] = useState(formData.valor || '')
  const [urlLogo, setUrlLogo] = useState(formData.url || '')
  const [activeValue, setActiveValue] = useState(formData.esFooter || false)
  const [colorPickerVisible, setColorPickerVisible] = useState(false)
  const [colorTextPickerVisible, setColorTextPickerVisible] = useState(false)
  const [controlAlert, setControlAlert] = useState({ datosC: null, show: false })
  const [categoryId, setCategoryId] = useState(formData.categoria?.id || null)
  const [type, setType] = useState({
    componentToRender: formData.componentToRender || null,
    typeValue: formData.tipoEnlace?.id || null,
  })

  const [types, setTypes] = useState({ data: [], isLoading: true, error: false })
  const [compDetailRequest, setCompDetailRequest] = useState({ isLoading: true, companyDetail: {} })

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: formData,
  })

  const getCompanyDetail = async compDetailId => {
    setCompDetailRequest(prev => ({ ...prev, isLoading: true }))
    try {
      const response = await getCompDetailById(compDetailId, token)
      const miCompDetail = response.data
      await loadValuesInputs(miCompDetail)
      setCompDetailRequest({ isLoading: false, companyDetail: miCompDetail })
    } catch (error) {
      console.error('Error fetching company details:', error)
    }
  }

  const loadValuesInputs = async miCompDetail => {
    const tipoEnlaceObj = await getLinkTypeById(parseInt(miCompDetail.tipoEnlace.id), token)
    const colorData = miCompDetail.tipoEnlace.nombre === 'color-footer' ? JSON.parse(miCompDetail.valor) : {}
    setType({
      typeValue: tipoEnlaceObj.data.id,
      componentToRender: tipoEnlaceObj.data.categoria.tipoValor,
      nameType: tipoEnlaceObj.data.nombre,
      descriptionType: tipoEnlaceObj.data.descripcion,
    })
    setValueValue(miCompDetail.valor)
    setActiveValue(miCompDetail.esFooter)
    setUrlLogo(miCompDetail.url)
    setColor(colorData.background || '#8dd3bb')
    setTextColor(colorData.text || '#FFFFFF')
    setCharacterCount(miCompDetail.valor.length)
  }

  const getCompanyTypesCombo = async () => {
    setTypes(prev => ({ ...prev, isLoading: true }))
    try {
      const response = await getCompanyTypes(token)
      const sortedData = response.data.sort((a, b) => a.descripcion.localeCompare(b.descripcion))
      setTypes({ data: sortedData, isLoading: false, error: false })
    } catch (error) {
      setTypes(prev => ({ ...prev, error: true }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const prepareCreate = data => {
    setControlAlert({ datosC: data, show: true })
  }

  const createWebData = () => {
    if (onSubmit) onSubmit(controlAlert.datosC)
    setControlAlert({ ...controlAlert, show: false })
  }

  const handleFormSubmit = data => {
    let formData = {
      empresaId: appId,
      tipoEnlace: { id: parseInt(type.typeValue) },
      valor: type.componentToRender !== COLOR ? valueValue : JSON.stringify({ background: color, text: textColor }),
      categoria: { id: categoryId },
      esFooter: activeValue,
      url: urlLogo,
    }

    try {
      if (onSubmit) onSubmit(formData)
    } catch (error) {
      console.error('Error updating company detail:', error)
    }
  }

  useEffect(() => {
    if (restProps.idSection) {
      getCompanyDetail(restProps.idSection)
    }
    getCompanyTypesCombo()
  }, [restProps.idSection])

  useEffect(() => {
    const handlePicker = e => {
      if (colorPickerVisible && !e.target.offsetParent?.className?.startsWith('ant-')) {
        setColorPickerVisible(false)
      }
      if (colorTextPickerVisible && !e.target.offsetParent?.className?.startsWith('ant-')) {
        setColorTextPickerVisible(false)
      }
    }
    document.addEventListener('mousedown', handlePicker)
    return () => document.removeEventListener('mousedown', handlePicker)
  }, [colorPickerVisible, colorTextPickerVisible])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(handleFormSubmit)} {...restProps}>
      <AlertDialogSimple
        isOpen={controlAlert.show}
        onClose={() => setControlAlert({ ...controlAlert, show: false })}
        onAccept={createWebData}
        acceptLabel={t('common:accept')}
      >
        {t('web-information:checked-disabled')} <br />
      </AlertDialogSimple>
      <Stack>
        <Box>
          <FormLabel htmlFor='type' isRequired>
            {t('forms:type')}
          </FormLabel>
          <SelectAsync
            isRequired
            isDisabled={true}
            placeholder={t('web-information:type')}
            name='type'
            value={type.typeValue}
            onChange={e => {
              const typeId = parseInt(e.target.value)
              const typeDetail = types.data.find(type => type.id === typeId)
              setValueValue(undefined)
              setCategoryId(parseInt(typeDetail.categoria.id))
              setType({
                componentToRender: typeDetail.categoria.tipoValor,
                typeValue: typeId,
                nameType: typeDetail.nombre,
                descriptionType: typeDetail.descripcion,
              })
            }}
            nativeProps={{ ref: register }}
            isLoading={types.isLoading}
          >
            {types.data.map(type => {
              return (
                <option key={type.id} value={type.id}>
                  {type.descripcion}
                </option>
              )
            })}
          </SelectAsync>
        </Box>

        {type.componentToRender === COLOR && (
          <Box>
            <Box>
              <FormLabel>{t('common:background')}</FormLabel>
              <ColorPicker value={color} onChange={color => setColor(`#${color.toHex()}`)} />
            </Box>
            <Box>
              <FormLabel>{t('common:letter')}</FormLabel>
              <ColorPicker value={textColor} onChange={color => setTextColor(`#${color.toHex()}`)} />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' backgroundColor={color} color={textColor}>
              <p>{t('common:exampleMarcaBlanca1')}</p>
              <p>{t('common:exampleMarcaBlanca2')}</p>
            </Box>
          </Box>
        )}

        {type.componentToRender === IMAGE && (
          <Grid gap={4}>
            <ImageInput
              name='value'
              value={valueValue}
              setValue={setValue}
              inputProps={{
                placeholder: `URL ${t('forms:image')}`,
                ref: register,
              }}
            />
            <Box>
              <FormLabel htmlFor='url'>{t('forms:url')}</FormLabel>
              <FormInput
                name='url'
                value={urlLogo}
                onChange={e => setUrlLogo(e.target.value)}
                inputProps={{
                  ref: register,
                }}
              />
            </Box>
          </Grid>
        )}

        {inputTextEntries.includes(type.componentToRender) && (
          <>
            {/* Valor */}
            <Box>
              {type.descriptionType !== 'Acerca de Nosotros' && type.nameType !== 'about-us-footer' ? (
                <FormInput
                  isRequired
                  name='value'
                  value={valueValue}
                  onChange={e => setValueValue(e.target.value)}
                  inputProps={{
                    type: 'text',
                    ref: register,
                  }}
                />
              ) : (
                <>
                  <Textarea
                    isRequired
                    name='value'
                    value={valueValue}
                    height={220}
                    onPaste={e => {
                      e.preventDefault()
                      const pastedData = e.clipboardData.getData('text')
                      const trimmedData = pastedData.slice(0, 500)
                      setValueValue(trimmedData)
                      setCharacterCount(trimmedData.length)
                    }}
                    onChange={e => {
                      const newValue = e.target.value
                      if (newValue.length <= 500) {
                        setValueValue(newValue)
                        setCharacterCount(newValue.length)
                      }
                    }}
                    inputProps={{
                      type: 'text',
                      ref: register,
                    }}
                  />
                  <div className={characterCount === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                    Characters: {characterCount}/{maxCharacterCount}
                  </div>
                </>
              )}
            </Box>
          </>
        )}

        {types.isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        ) : (
          <>
            <CustomCheckbox
              label={t('web-information:active')}
              active={activeValue}
              setActiveValue={setActiveValue}
              justifyContent='center'
              alignItems='center'
              mt={4}
            />
            <Button mt={6} variantColor='blue' variant='outline' type='submit'>
              {t('forms:save')}
            </Button>
          </>
        )}
      </Stack>
    </PseudoBox>
  )
}

export default WebDataForm
